import { RppQuestionType } from "../models/patient/rpp-question-type.model";

export interface RppQuestion {
  id: number;
  translation: RppQuestionType;
  notSpecifiedTranslation: string;
}

export const RPP_QUESTIONS: RppQuestion[] = [
  {
    id: 1,
    translation: "weight_control_less_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 2,
    translation: "meal_portion_control",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 3,
    translation: "weight_food_refusal",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 4,
    translation: "food_amount_control",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 5,
    translation: "weight_loss_food_choice",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 6,
    translation: "overeating_compensation",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 7,
    translation: "weight_gain_prevention",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 8,
    translation: "snacks_weight_control",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 9,
    translation: "evening_eating_control",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 10,
    translation: "weight_conscious_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 11,
    translation: "emotional_eating_irritation",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 12,
    translation: "boredom_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 13,
    translation: "depression_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 14,
    translation: "loneliness_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 15,
    translation: "disappointment_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 16,
    translation: "frustration_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 17,
    translation: "anticipation_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 18,
    translation: "anxiety_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 19,
    translation: "stress_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 20,
    translation: "fear_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 21,
    translation: "hopelessness_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 22,
    translation: "upset_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 23,
    translation: "fatigue_eating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 24,
    translation: "tasty_food_overeating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 25,
    translation: "appetizing_food_overeating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 26,
    translation: "food_temptation_sight_smell",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 27,
    translation: "immediate_food_consumption",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 28,
    translation: "bakery_temptation",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 29,
    translation: "cafe_temptation",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 30,
    translation: "social_eating_trigger",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 31,
    translation: "tasty_food_self_control",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 32,
    translation: "social_overeating",
    notSpecifiedTranslation: "no_answer",
  },
  {
    id: 33,
    translation: "cooking_tasting",
    notSpecifiedTranslation: "no_answer",
  },
];
